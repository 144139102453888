<template>
  <modal-wrapper
    :id="id"
    hide-footer
    size="md"
    :on-modal-show="loadPaypalScript"
    :on-modal-hidden="doReset"
    :no-auto-close="disableClose"
  >
    <template #title>
      {{modalTitle}}
    </template>
    <template #body>
      <div class="payment-modal-content" v-if="!inProgress">
        <div
          v-if="hasPreloader"
          class="mt-5"
        >
          <loader-info />
        </div>
        <div v-if="!success" id="paypal-button-container"></div>
      </div>
        <div v-if="success" class="text-center">
          <font-awesome-icon icon="check-circle" class="text-success fa-5x my-3" />
          <h5>
            <strong>
              Acquisto completato con successo
            </strong>
          </h5>
          <div class="text-right mt-3 mx-n3 border-top pt-3 px-3">
            <b-btn @click="closeModal" variant="primary">Chiudi</b-btn>
          </div>
        </div>
        <div v-else-if="failed" class="text-center">
          <font-awesome-icon icon="circle-exclamation" class="text-success fa-5x my-3" />
          <h5>
            <strong>
              Non è stato possibile effettuare l'acquisto.
              <br />
              Riprova più tardi.
            </strong>
          </h5>
          <div>
            <label v-for="(error, index)  in paymentErrorMessage" :key="index">
              {{error}}
            </label>
          </div>
          <div class="text-right mt-3 mx-n3 border-top pt-3 px-3">
            <b-btn @click="closeModal" variant="danger">Annulla</b-btn>
          </div>
        </div>
    </template>
  </modal-wrapper>
</template>

<script>
import { loadScript } from '@paypal/paypal-js';
import { isNotEmpty, extractErrorMessage } from '@/utils/validators';
import WalletService from '@/services/wallet.service';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');

export default {
  name: 'ModalPayment',
  components: {
    ModalWrapper,
    LoaderInfo,
  },
  props: {
    id: String,
    subjectInvoiceType: String,
    subjectInvoiceId: String,
    product: {
      productId: String,
      description: String,
      price: {
        currency: String,
        amount: Number,
      },
      creditsPurchased: {
        currency: String,
        amount: Number,
      },
    },
  },
  // beforeCreate() {
  //   const clientId = this.$store.getters['wallet/getClientId'];
  //   loadScript({ 'client-id': clientId }).then((paypal) => {
  //     paypal
  //       .Buttons({
  //         createOrder: this.createOrder,
  //         onApprove: this.onApprove,
  //       })
  //       .render('#paypal-button-container');
  //   });
  // },
  data() {
    return {
      initComplete: false,
      token: null,
      inProgress: false,
      success: false,
      failed: false,
      paymentError: null,
      paypal: {
        flow: 'vault',
      },
      braintreeInterface: null,
      has3DSecure: false,
      searchBase: {
        search: '',
        page: 0,
        size: 10,
        sort: [{ field: 'operationDate', direction: 'DESC' }],
        filters: [],
      },
      submitted: false,
      hasPreloader: true,
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    disableClose() {
      return this.inProgress && !this.failed;
    },
    entityType() {
      return this.isCompanyProfile ? 'company' : 'person';
    },
    entityId() {
      return this.isCompanyProfile
        ? this.$store.getters['subject/currentCompany'].companyId
        : this.$store.getters['subject/currentPerson'].personId;
    },
    paymentErrorMessage() {
      const errors = [];
      if (this.paymentError) {
        const { message, _braintreeWebError } = this.paymentError;
        errors.push(message);
        if (_braintreeWebError) {
          const { details } = _braintreeWebError;
          errors.push(_braintreeWebError.message);
          if (details) {
            const { originalError } = details;
            if (originalError) {
              errors.push(originalError.message);
              if (originalError.details) {
                const { httpStatus } = originalError.details;
                errors.push(`Error status: ${httpStatus}. ${originalError.details.originalError?.error?.message}`);
              }
            }
          }
        }
      }
      return errors;
    },
    modalTitle() {
      let titleVar = 'Acquisto Ricarica';
      if (isNotEmpty(this.product.description)) titleVar = `Acquisto ${this.product.description}`;
      return titleVar;
    },
  },
  methods: {
    doReset() {
      console.log('trying reset');
      this.success = false;
      this.failed = false;
      this.inProgress = false;
      this.initComplete = false;
    },
    onSuccess(event) {
      this.inProgress = true;
      console.debug('onSuccess', event);

      WalletService.rechargePayment({
        paymentMethodNonce: event.nonce,
        productId: this.product.productId,
        entityId: this.entityId,
        entityType: this.entityType,
        subjectInvoiceId: this.subjectInvoiceId,
        subjectInvoiceType: this.subjectInvoiceType,
      }).then((response) => {
        this.success = true;
        // console.log('this.entityType');
        this.$store.dispatch('wallet/loadWallet', {
          entityType: this.entityType,
          entityId: this.entityId,
        });
        this.fetchData(false, 1);
        console.log('success + loadwallet', response);
      });
    },
    onError(event) {
      this.inProgress = true;
      this.failed = true;
      console.debug('onError', event);
      this.paymentError = event;
    },
    closeModal() {
      console.log('closing ', this.id);
      this.$bvModal.hide(this.id);
    },
    loadComplete(e) {
      console.log('payment loadComplete', e);
      // this.initComplete = true;
      this.braintreeInterface = e;
      this.braintreeInterface.on('changeActiveView', this.changeView);
      this.braintreeInterface.on('paymentOptionSelected', this.paymentSelect);
    },
    changeView(e) {
      console.log('payment changeView', e);
      if (e.newViewId === 'options') {
        this.initComplete = false;
      }
    },
    paymentSelect(e) {
      console.log('payment paymentSelect', e);
      this.initComplete = true;
    },
    fetchData(reload, nextPage) {
      console.log('list fectad', this.searchBase, nextPage);
      // const paging = this.$store.getters['subject/investmentPageable'];
      // const search = { ...this.searchBase, ...paging };
      const search = { ...this.searchBase };
      // console.log('fetch', nextPage);
      if (isNotEmpty(nextPage)) {
        const paging = this.$store.getters['wallet/userTransactionListPageable'];
        if (nextPage > 0 && nextPage <= paging.totalPages) {
          search.page = nextPage - 1;
        }
      }
      const currentEntityType = this.entityType;
      const currentEntityId = this.entityId;
      this.$store.dispatch('wallet/transactionsList', {
        entityType: currentEntityType, entityId: currentEntityId, search, reset: reload,
      })
        .then(() => {
          this.submitted = false;
          this.searchBase.page = search.page;
        },
        (error) => {
          this.message = extractErrorMessage(error);
          this.submitted = false;
        });
    },
    loadPaypalScript() {
      console.log('Modal payment show');
      this.initComplete = false;
      const clientId = this.$store.getters['wallet/getClientId'];
      const paypalOptions = {
        'client-id': clientId,
        currency: 'EUR',
        'disable-funding': 'paylater,bancontact,blik,mybank,eps,giropay,ideal,mercadopago',
        locale: 'it_IT',
      };
      loadScript(paypalOptions).then((paypal) => {
        this.hasPreloader = false;
        paypal
          .Buttons({
            createOrder: this.createOrder,
            onApprove: this.onApprove,
            onCancel: this.onCancel,
            onError: this.onPaypalError,
          })
          .render('#paypal-button-container');
      });
    },
    createOrder(data /* , actions */) {
      console.log('Creating order...', data);
      return WalletService.rechargePayment({
        paymentMethodNonce: 'PAYPAL',
        productId: this.product.productId,
        entityId: this.entityId,
        entityType: this.entityType,
        subjectInvoiceId: this.subjectInvoiceId,
        subjectInvoiceType: this.subjectInvoiceType,
      }).then((response) => {
        console.log('create order', response.data);
        return response.data.data;
      }).then((order) => order.id);
    },
    onApprove(data /* , actions */) {
      console.log('Order approved...', data);
      return WalletService.completeOrderPayment(data.orderID)
        .then((response) => {
          this.success = true;
          this.inProgress = true;
          // console.log('this.entityType');
          this.$store.dispatch('wallet/loadWallet', {
            entityType: this.entityType,
            entityId: this.entityId,
          });
          this.fetchData(false, 1);
          console.log('success + loadwallet', response);
        })
        .catch((error) => {
          console.log('Complete order error', error);
        });
      // return actions.order.capture().then(() => {
      //   this.paid = true;
      //   console.log('Order complete!');
      // });
    },
    onCancel(data) {
      // Show a cancel page, or return to cart
      console.log('onCancel', data);
      return WalletService.deleteOrderPayment(data.orderID);
    },
    onPaypalError(err) {
      console.log('onError', err);
    },
  },
};
</script>

<style scoped>
#paypal-button-container {
  margin: 30px 0;
}
</style>
